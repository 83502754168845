export const routes = {
  home: {
    name: "Home",
    url: "/",
  },
  blog: {
    name: "Blog",
    url: "/blog/:blogId",
  },
  blogs: {
    name: "Blogs",
    url: "/blog-list",
  },
  about: {
    name: "About",
    url: "/about-us",
  },
  faqs: {
    name: "FAQs",
    url: "/faqs",
  },
  contact: {
    name: "Contact",
    url: "/contact-us",
  },
  hostels: {
    name: "Hostels",
    url: "/hostels",
  },
  hostel: {
    name: "Hostel",
    url: "/hostel",
  },
  login: {
    name: "Login",
    url: "/login",
  },
  forgetPassword: {
    name: "ForgetPassword",
    url: "/forget-password",
  },
  resetPassword: {
    name: "ResetPassword",
    url: "/reset-password",
  },
  dashboard: {
    name: "Dashboard",
    url: "/dashboard",
  },
  profile: {
    name: "Profile",
    url: "/dashboard/profile",
  },
  settings: {
    name: "Settings",
    url: "/dashboard/settings",
  },
};
