import { houseActionTypes } from "../../actionTypes";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  data: [],
};

export const getAllHouses = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case houseActionTypes.GET_ALL_HOUSES_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case houseActionTypes.GET_ALL_HOUSES_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload,
      };
    case houseActionTypes.GET_ALL_HOUSES_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};

export const getHouse = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case houseActionTypes.GET_HOUSE_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case houseActionTypes.GET_HOUSE_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload,
      };
    case houseActionTypes.GET_HOUSE_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};

export const addHouse = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case houseActionTypes.ADD_HOUSE_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case houseActionTypes.ADD_HOUSE_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload,
      };
    case houseActionTypes.ADD_HOUSE_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};

export const searchHouse = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case houseActionTypes.SEARCH_HOUSE_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case houseActionTypes.SEARCH_HOUSE_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload,
      };
    case houseActionTypes.SEARCH_HOUSE_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};
