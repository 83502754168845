import React from "react";
import "./Layout.scss";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import ShowStats from "../ShowStats";

const Layout = ({ children }) => {
  return (
    <>
      <div className="show_dashboard">
        <Sidebar />
        <div className="show_dashboard_app_container">
          <div className="show_header">
            <Navbar />
          </div>

          <div className="show_dashboard_body">
            <div className="show_dashboard_pages">{children}</div>
            <ShowStats />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
