import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, message } from "antd";
import { routes } from "../../config/routes";
import red_icon from "../../images/sirlogo.png";
import "./Login.scss";
import { emailRules, passwordRules } from "../../components/admin/Validations";
import { login } from "../../redux/actions/auth";
import { auth } from "../../config/firebase";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const { loading, successMessage, errorMessage } = useSelector(
    (state) => state.Login
  );

  const onFinish = async (values) => {
    dispatch(login(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const goHome = () => {
    history.push(routes.home.url);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      const user = {
        uid: authUser?.uid,
        email: authUser?.email,
      };
      if (authUser) {
        setUser(user);
        localStorage.setItem("sirUser", user?.uid);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (successMessage) {
      message.success(successMessage);
    } else if (errorMessage) {
      message.error(errorMessage);
    }
  }, [successMessage, errorMessage]);

  return (
    <>
      {user ? (
        <Redirect to="/dashboard" />
      ) : (
        <div className="show_auth_page">
          <div className="auth_page_content">
            <div className="auth_page_container">
              <div onClick={goHome} className="show_icon show_mb_32">
                <img src={red_icon} alt="" />
              </div>
              <p className="show_pb_1 show_big_text show_600_weight">Login</p>

              <Form
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item label="Email" name="email" rules={emailRules}>
                  <Input className="show_input" placeholder="Email" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={passwordRules}
                >
                  <Input.Password
                    className="show_input"
                    placeholder="Enter password"
                  />
                </Form.Item>

                <Form.Item className="show_auth_btn_container">
                  <Button
                    className="show_btn show_primary_btn show_auth_btn"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>

              {/* <p className="text-center">
            <span className="show_mute_text">Forget password?</span>{" "}
            <Link className="swow_auth_link" to={routes.forgetPassword.url}>
              Reset
            </Link>
          </p> */}
            </div>
          </div>

          <div className="auth_page_cliped"></div>
        </div>
      )}
    </>
  );
};

export default Login;
