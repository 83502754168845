import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { routes } from "../../config/routes";
import red_icon from "../../images/sirlogo.png";
import "./ForgetPassword.scss";
import { emailRules } from "../../components/admin/Validations";

const ForgetPassword = () => {
  const history = useHistory();
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleLogin = () => {
    history.push(routes.resetPassword.url);
  };
  return (
    <div className="show_auth_page">
      <div className="auth_page_content">
        <div className="auth_page_container">
          <div className="show_auth_loading" />
          <div className="show_icon">
            <img className="show_pb_1" src={red_icon} alt="" />
          </div>
          <p className="show_pb_1 show_big_text show_600_weight">
            Forget password
          </p>
          <p className="show_mute_text">
            Use your ShowApp email to login to reset your password.
          </p>
          <div className="show_pt_2">
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item label="Email" name="email" rules={emailRules}>
                <Input className="show_input" placeholder="Email" />
              </Form.Item>

              <Form.Item className="show_auth_btn_container">
                <Button
                  className="show_btn show_primary_btn show_auth_btn"
                  type="primary"
                  htmlType="submit"
                  onClick={handleLogin}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>

          <p className="text-center">
            <span className="show_mute_text">Back to?</span>{" "}
            <Link className="swow_auth_link" to={routes.login.url}>
              Login
            </Link>
          </p>
        </div>
      </div>
      <div className="auth_page_cliped"></div>
    </div>
  );
};

export default ForgetPassword;
