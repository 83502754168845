export const passwordRules = [
  {
    required: true,
    message: "Please enter password!",
  },
];

export const emailRules = [
  {
    type: "email",
    required: true,
    message: "Sorry boss! That's not a valid email",
  },
];

export const requiredRules = [
  {
    required: true,
    message: "This is required",
  },
];
