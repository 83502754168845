import React from "react";
import { useHistory } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "./Navbar.scss";
import { handleTitle } from "../../../helpers/handleTitle";

const Navbar = () => {
  const history = useHistory();

  return (
    <div className="show_navbar">
      <h6 className="show_big_text show_600_weight">
        {history.location.pathname && history.location.pathname
          ? handleTitle(history.location.pathname)
          : "Show Dashboard"}
      </h6>

      <div className="show_navbar_right">
        <div className="profile_info show_cursored">
          <FaUserCircle className="profile_icon" />
          <span className="show_black_text">Sir Admin</span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
