import React, { Fragment } from "react";
import Navbar from "../global-components/navbar";
import Footer from "../global-components/footer";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Navbar />
      {children}
      <Footer />
    </Fragment>
  );
};

export default Layout;
