import React from "react";
import { AiFillHome } from "react-icons/ai";
// import { FaUserCircle } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { routes } from "../../../config/routes";

export const webMenus = [
  {
    id: 1,
    title: "Home",
    icon: <AiFillHome />,
    url: routes.dashboard.url,
  },
  // {
  //   id: 2,
  //   title: "Profile",
  //   icon: <FaUserCircle />,
  //   url: routes.profile.url,
  // },
  {
    id: 3,
    title: "Settings",
    icon: <IoSettingsSharp />,
    url: routes.settings.url,
  },
];
