import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Social from "../social";
import { getAllBlogs } from "../../redux/actions/blogActions";
import { Spinner } from "../../components/loaders";

const Footer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.Blogs);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  const viewPost = (id) => {
    history.push(`/blog/${id}`);
    window.scrollTo(10, 0);
  };

  return (
    <div className="footer-area bg-black pd-top-95">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="widget">
              <h5 className="widget-title">Study in Rwanda</h5>
              <div className="widget_about">
                <p>
                  Study in Rwanda is an international program that assists
                  international students who wish to upgrade their academic
                  profile by studying in Rwanda.
                </p>
                <ul className="social-area social-area-2 mt-4">
                  <Social />
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="widget">
              <h5 className="widget-title">Contacts</h5>
              <ul className="contact_info_list">
                <li>
                  <i className="fa fa-map-marker" /> Kibagabaga, Kigali Rwanda.
                </li>
                <li>
                  <i className="fa fa-phone" /> WhatsApp: +250788300257
                </li>
                <li>
                  <i className="fa fa-envelope-o" /> admissions@sir.rw <br />{" "}
                  info@studyinrwanda.rw
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 go-top">
            <div className="widget widget_recent_post">
              <h5 className="widget-title">Most viewed</h5>

              <div className="row">
                {loading ? (
                  <div className="col-lg-6 col-md-6 col-12">
                    <Spinner />
                  </div>
                ) : (
                  data?.slice(0, 4).map((post) => (
                    <div
                      onClick={() => viewPost(post?.id)}
                      className="col-lg-6 col-md-6 col-12"
                    >
                      <div
                        key={post?.id}
                        className="single-post-list-wrap style-white"
                      >
                        <div className="media">
                          <div className="media-left">
                            <img src={post?.photoUrl} alt="img" />
                          </div>
                          <div className="media-body">
                            <div className="details">
                              <div className="post-meta-single">
                                <ul>
                                  <li>
                                    <i className="fa fa-clock-o" />
                                    {post?.postDate}
                                  </li>
                                </ul>
                              </div>
                              <h6 className="title">
                                <Link to="#">{post?.title}</Link>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <p>Copyright ©{new Date().getFullYear()} Study in Rwanda</p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
