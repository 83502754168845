import { auth } from "../../../config/firebase";
import { authActionTypes } from "../../actionTypes";

export const login = (data) => async (dispatch) => {
  try {
    dispatch({
      type: authActionTypes.LOGIN_REQUEST,
    });

    const user = await auth.signInWithEmailAndPassword(
      data?.email,
      data?.password
    );

    dispatch({
      type: authActionTypes.LOGIN_SUCCESS,
      payload: user,
    });
  } catch (error) {
    dispatch({
      type: authActionTypes.LOGIN_FAIL,
      payload: error.message,
    });
  }
};

export const addUser = (data) => async (dispatch) => {
  try {
    dispatch({
      type: authActionTypes.ADD_USER_REQUEST,
    });

    await auth.createUserWithEmailAndPassword(data?.email, data?.password);

    dispatch({
      type: authActionTypes.ADD_USER_SUCCESS,
      payload: "User Added successfuly",
    });
  } catch (error) {
    dispatch({
      type: authActionTypes.ADD_USER_FAIL,
      payload: error.message,
    });
  }
};
