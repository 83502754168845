import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC9LNQr4dnj4MbjhqROSlhQaBiFPZUKkU4",
  authDomain: "sirweb-4cc42.firebaseapp.com",
  projectId: "sirweb-4cc42",
  storageBucket: "sirweb-4cc42.appspot.com",
  messagingSenderId: "361769869053",
  appId: "1:361769869053:web:e192510e22cb920937a894",
  measurementId: "G-BNM49Y9BP8",
};

let app = null;

firebase.apps.length === 0
  ? (app = firebase.initializeApp(firebaseConfig))
  : (app = firebase.app());

export const db = app.firestore();
export const auth = app.auth();
export const storage = app.storage();
