import React, { Fragment, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import LazyLoadingLoader from "../../components/admin/Loaders/LazyLoadingLoader";
import DashboardLayout from "../../components/admin/Layout";
import { routes } from "../../config/routes";

import Login from "../../pages/login";
import ForgetPassword from "../../pages/forgetPassword";
import ResetPassword from "../../pages/ResetPassword";

const Home = React.lazy(() => import("../../pages/home"));
const Blog = React.lazy(() => import("../../pages/blog"));
const Blogs = React.lazy(() => import("../../pages/blogs"));
const About = React.lazy(() => import("../../pages/about"));
const FAQs = React.lazy(() => import("../../pages/faqs"));
const Contact = React.lazy(() => import("../../pages/contact"));
const Hostels = React.lazy(() => import("../../pages/hostels"));
const Hostel = React.lazy(() => import("../../pages/hostel"));
const Book = React.lazy(() => import("../../pages/book"));
const NotFound = React.lazy(() => import("../../pages/notFound"));
const PrivateRoutes = React.lazy(() => import("../privateRoutes"));

const Routes = () => {
  const history = useHistory();

  return (
    <Fragment>
      <Suspense
        fallback={
          <>
            {history.location.pathname.includes("sir-ad-m-i-n-login") ||
            history.location.pathname.includes("dashboard") ? (
              <DashboardLayout>
                <LazyLoadingLoader />
              </DashboardLayout>
            ) : (
              <Layout>
                <div
                  style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spin />
                </div>
              </Layout>
            )}
          </>
        }
      >
        <Switch>
          <Route path={routes.home.url} exact>
            <Layout>
              <Home />
            </Layout>
          </Route>

          <Route path="/blog/:blogId" exact>
            <Layout>
              <Blog />
            </Layout>
          </Route>

          <Route path={routes.blogs.url} exact>
            <Layout>
              <Blogs />
            </Layout>
          </Route>

          <Route path={routes.about.url} exact>
            <Layout>
              <About />
            </Layout>
          </Route>

          <Route path={routes.faqs.url} exact>
            <Layout>
              <FAQs />
            </Layout>
          </Route>

          <Route path={routes.contact.url} exact>
            <Layout>
              <Contact />
            </Layout>
          </Route>

          <Route path={routes.hostels.url} exact>
            <Layout>
              <Hostels />
            </Layout>
          </Route>

          <Route path="/hostel/:hostelId" exact>
            <Layout>
              <Hostel />
            </Layout>
          </Route>

          <Route path="/hostel/:hostelId/book" exact>
            <Layout>
              <Book />
            </Layout>
          </Route>

          <Route path={routes.login.url} exact>
            <Login />
          </Route>

          <Route path={routes.forgetPassword.url} exact>
            <ForgetPassword />
          </Route>

          <Route path={routes.resetPassword.url} exact>
            <ResetPassword />
          </Route>

          <Route component={PrivateRoutes} />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Routes;
