import { blogActionTypes } from "../../actionTypes";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  data: [],
};

export const getAllBlogs = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case blogActionTypes.GET_ALL_BLOGS_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case blogActionTypes.GET_ALL_BLOGS_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload,
      };
    case blogActionTypes.GET_ALL_BLOGS_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};

export const getBlog = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case blogActionTypes.GET_BLOG_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case blogActionTypes.GET_BLOG_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload,
      };
    case blogActionTypes.GET_BLOG_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};

export const addBlog = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case blogActionTypes.ADD_BLOG_REQUEST:
      return {
        ...prevState,
        loading: true,
      };

    case blogActionTypes.ADD_BLOG_SUCCESS:
      return {
        ...prevState,
        loading: false,
        successMessage: payload,
      };

    case blogActionTypes.ADD_BLOG_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};

export const deleteBlog = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case blogActionTypes.DELETE_BLOG_REQUEST:
      return {
        ...prevState,
        loading: true,
      };

    case blogActionTypes.DELETE_BLOG_SUCCESS:
      return {
        ...prevState,
        loading: false,
        successMessage: payload,
      };

    case blogActionTypes.DELETE_BLOG_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};
