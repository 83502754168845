import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../config/routes";
import Social from "../social";
import sirlogo from "../../images/sirlogo.png";
import bn2 from "../../images/banners/two.jpg";

const Navbar = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="navbar-area">
      <div className="topbar-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7 align-self-center">
              <div className="topbar-menu text-md-left text-center">
                <ul className="align-self-center">
                  <li>
                    <a href="mailto:admissions@sir.rw">
                      Email: admissions@sir.rw
                    </a>
                    ,{" "}
                    <a href="mailto:info@studyinrwanda.rw">
                      info@studyinrwanda.rw
                    </a>
                  </li>
                  <li>
                    <a href="tel:+250788300257">WhatsApp: +250788300257</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 text-md-right text-center mt-2 mt-md-0">
              <div className="topbar-social">
                <ul className="social-area social-area-2">
                  <a
                    href="https://apply.sir.rw/apply"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    International students apply here
                  </a>
                  <Social />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="adbar-area d-none d-lg-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 align-self-center">
              <div className="logo text-md-left text-center">
                <Link className="main-logo" to="/">
                  <img src={publicUrl + "assets/img/sirlogo.png"} alt="img" />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 text-md-right text-center">
              <a
                href="https://apply.sir.rw/apply"
                target="_blank"
                rel="noopener noreferrer"
                className="adbar-right"
              >
                <img src={bn2} alt="img" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <div className="logo d-lg-none d-block">
              <Link className="main-logo" to="/">
                <img src={sirlogo} alt="img" />
              </Link>
            </div>
            <button
              className="menu toggle-btn d-block d-lg-none"
              data-target="#sir_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="nav-right-part nav-right-part-mobile">
            <a className="search header-search" href="/">
              <i className="fa fa-search" />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="sir_main_menu">
            <ul className="navbar-nav menu-open">
              <li className="current-menu-item">
                <Link to={routes.home.url}>Home</Link>
              </li>
              <li className="current-menu-item">
                <Link to={routes.blogs.url}>News</Link>
              </li>
              <li className="current-menu-item">
                <Link to={routes.hostels.url}>Hostels</Link>
              </li>

              <li className="current-menu-item">
                <a
                  href="https://apply.sir.rw/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  UNIVERSITIES
                </a>
              </li>

              <li className="current-menu-item">
                <a
                  href="https://apply.sir.rw/apply"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply
                </a>
              </li>
              <li className="current-menu-item">
                <Link to={routes.about.url}>About us</Link>
              </li>

              <li className="current-menu-item">
                <Link to={routes.contact.url}>Contact us</Link>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop">
            <div className="menu-search-inner">
              <a
                href="https://apply.sir.rw/search/type-it"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-blue"
              >
                Search For program
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
