export const blogActionTypes = {
  GET_ALL_BLOGS_REQUEST: "GET_ALL_BLOGS_REQUEST",
  GET_ALL_BLOGS_SUCCESS: "GET_ALL_BLOGS_SUCCESS",
  GET_ALL_BLOGS_FAIL: "GET_ALL_BLOGS_FAIL",

  GET_BLOG_REQUEST: "GET_BLOG_REQUEST",
  GET_BLOG_SUCCESS: "GET_BLOG_SUCCESS",
  GET_BLOG_FAIL: "GET_BLOG_FAIL",

  ADD_BLOG_REQUEST: "ADD_BLOG_REQUEST",
  ADD_BLOG_SUCCESS: "ADD_BLOG_SUCCESS",
  ADD_BLOG_FAIL: "ADD_BLOG_FAIL",

  DELETE_BLOG_REQUEST: "DELETE_BLOG_REQUEST",
  DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
  DELETE_BLOG_FAIL: "DELETE_BLOG_FAIL",
};

export const houseActionTypes = {
  GET_ALL_HOUSES_REQUEST: "GET_ALL_HOUSES_REQUEST",
  GET_ALL_HOUSES_SUCCESS: "GET_ALL_HOUSES_SUCCESS",
  GET_ALL_HOUSES_FAIL: "GET_ALL_HOUSES_FAIL",

  GET_HOUSE_REQUEST: "GET_HOUSE_REQUEST",
  GET_HOUSE_SUCCESS: "GET_HOUSE_SUCCESS",
  GET_HOUSE_FAIL: "GET_HOUSE_FAIL",

  ADD_HOUSE_REQUEST: "ADD_HOUSE_REQUEST",
  ADD_HOUSE_SUCCESS: "ADD_HOUSE_SUCCESS",
  ADD_HOUSE_FAIL: "ADD_HOUSE_FAIL",

  SEARCH_HOUSE_REQUEST: "SEARCH_HOUSE_REQUEST",
  SEARCH_HOUSE_SUCCESS: "SEARCH_HOUSE_SUCCESS",
  SEARCH_HOUSE_FAIL: "SEARCH_HOUSE_FAIL",
};

export const authActionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",

  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAIL: "ADD_USER_FAIL",
};
