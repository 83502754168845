import React from "react";
import "./ShowStats.scss";

const ShowStats = () => {
  return (
    <div className="show_stats">
      <div className="show_margin_1">
        <div className="header">
          <span className="show_big_text show_600_weight">Stats</span>
        </div>
        <div className="show_update_prem show_cursored">
          <h6 className="show_medium_text show_uppercased_text show_600_weight show_red_text">
            Sir dashboard
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ShowStats;
