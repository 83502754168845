import { combineReducers } from "redux";

import { getAllBlogs, getBlog, addBlog, deleteBlog } from "./blogReducers";
import { getAllHouses, getHouse } from "./hostelReducers";
import { LoginReducer, addUserReducer } from "./auth";

const reducers = combineReducers({
  Blogs: getAllBlogs,
  Blog: getBlog,
  AddBlog: addBlog,
  DeleteBlog: deleteBlog,
  Houses: getAllHouses,
  House: getHouse,
  Login: LoginReducer,
  AddUser: addUserReducer,
});

export default reducers;
