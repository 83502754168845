import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { routes } from "../../config/routes";
import red_icon from "../../images/sirlogo.png";
import "./ResetPassword.scss";
import { passwordRules } from "../../components/admin/Validations";

const ResetPassword = () => {
  const history = useHistory();
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleLogin = () => {
    history.push(routes.resetPassword.url);
  };
  return (
    <div className="show_auth_page">
      <div className="auth_page_content">
        <div className="auth_page_container">
          <div className="show_auth_loading" />
          <div className="show_icon">
            <img className="show_pb_1" src={red_icon} alt="" />
          </div>
          <p className="show_pb_1 show_big_text show_600_weight">
            Set new password
          </p>
          <p className="show_mute_text">
            Lorem Ipsum is simply dummy text typesetting industry.
          </p>
          <div className="show_pt_2">
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item label="Password" name="password" rules={passwordRules}>
                <Input.Password
                  className="show_input"
                  placeholder="Enter password"
                />
              </Form.Item>

              <Form.Item className="show_auth_btn_container">
                <Button
                  className="show_btn show_primary_btn show_auth_btn"
                  type="primary"
                  htmlType="submit"
                  onClick={handleLogin}
                >
                  Change password
                </Button>
              </Form.Item>
            </Form>
          </div>

          <p className="text-center">
            <span className="show_mute_text">Recall password?</span>{" "}
            <Link className="swow_auth_link" to={routes.login.url}>
              Login
            </Link>
          </p>
        </div>
      </div>
      <div className="auth_page_cliped"></div>
    </div>
  );
};

export default ResetPassword;
