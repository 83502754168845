import React from "react";

const Social = () => {
  return (
    <>
      <li>
        <a
          className="facebook-icon"
          href="https://www.facebook.com/studyin.rwanda.9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-facebook" />
        </a>
      </li>
      <li>
        <a
          className="twitter-icon"
          href="https://twitter.com/RwandaStudyin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-twitter" />
        </a>
      </li>
      <li>
        <a
          className="youtube-icon"
          href="https://www.youtube.com/channel/UCyENy45rg7v818eWFuSXTCw"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-youtube-play" />
        </a>
      </li>
      <li>
        <a
          className="instagram-icon"
          href="https://www.linkedin.com/in/study-in-rwanda-b83a6217a/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-linkedin"></i>
        </a>
      </li>
    </>
  );
};

export default Social;
