import React from "react";
import { Link, useHistory } from "react-router-dom";
import { RiLogoutCircleLine } from "react-icons/ri";
import { webMenus } from "../Menus";
import sirlogo from "../../../images/sirlogo.png";
import "./Sidebar.scss";
import { routes } from "../../../config/routes";
import { auth } from "../../../config/firebase";

const Sidebar = () => {
  const history = useHistory();
  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem("sirUser");
    history.push("/login");
  };

  return (
    <div className="show_sidebar">
      <div className="show_side_top">
        <Link to={routes.dashboard.url}>
          <img src={sirlogo} alt="" />
        </Link>
      </div>

      <div className="show_menus">
        {webMenus?.map((menu) => (
          <Link
            key={menu?.id}
            className={`show_menu ${
              menu.url === history.location.pathname ? "active" : ""
            }`}
            to={menu?.url}
          >
            <span className="icon">{menu?.icon}</span>
            <span className="menu_title">{menu?.title}</span>
          </Link>
        ))}
      </div>

      <div className="show_side_bottom">
        <div className="logout_menu" onClick={handleLogout}>
          <RiLogoutCircleLine className="icon" />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
