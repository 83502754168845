import { db } from "../../../config/firebase";
import { blogActionTypes } from "../../actionTypes";

export const getAllBlogs = () => async (dispatch) => {
  try {
    dispatch({
      type: blogActionTypes.GET_ALL_BLOGS_REQUEST,
    });

    await db.collection("blogs").onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        author: doc.data()?.author,
        details: doc.data()?.details,
        photoUrl: doc.data()?.photoUrl,
        postDate: doc.data()?.postDate,
        title: doc.data()?.title,
      }));

      dispatch({
        type: blogActionTypes.GET_ALL_BLOGS_SUCCESS,
        payload: data,
      });
    });
  } catch (error) {
    dispatch({
      type: blogActionTypes.GET_ALL_BLOGS_FAIL,
      payload: error,
    });
  }
};

export const getBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: blogActionTypes.GET_BLOG_REQUEST,
    });

    const snapshot = await db.collection("blogs").doc(id).get();
    const data = snapshot.data();

    dispatch({
      type: blogActionTypes.GET_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: blogActionTypes.GET_BLOG_FAIL,
      payload: error,
    });
  }
};

export const addBlog = (data) => async (dispatch) => {
  try {
    dispatch({
      type: blogActionTypes.ADD_BLOG_REQUEST,
    });

    await db.collection("blogs").add({
      postDate: new Date().getDate(),
      title: data?.title,
      author: "Study in Rwanda",
      details: data?.details,
      photoUrl: data?.photoUrl,
    });

    dispatch({
      type: blogActionTypes.ADD_BLOG_SUCCESS,
      payload: "Added",
    });
  } catch (error) {
    dispatch({
      type: blogActionTypes.ADD_BLOG_FAIL,
      payload: error.message,
    });
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: blogActionTypes.DELETE_BLOG_REQUEST,
    });

    await db.collection("blogs").doc(id).delete();

    dispatch({
      type: blogActionTypes.DELETE_BLOG_SUCCESS,
      payload: "Deleted successflly",
    });
  } catch (error) {
    dispatch({
      type: blogActionTypes.DELETE_BLOG_FAIL,
      payload: error,
    });
  }
};
