import React from "react";
import { Spin } from "antd";

const LazyLoadingLoader = () => {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </div>
  );
};

export default LazyLoadingLoader;
