import { authActionTypes } from "../../actionTypes";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  data: [],
};

export const LoginReducer = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case authActionTypes.LOGIN_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case authActionTypes.LOGIN_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload,
      };
    case authActionTypes.LOGIN_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};

export const addUserReducer = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case authActionTypes.ADD_USER_REQUEST:
      return {
        ...prevState,
        loading: true,
      };
    case authActionTypes.ADD_USER_SUCCESS:
      return {
        ...prevState,
        loading: false,
        successMessage: payload,
      };
    case authActionTypes.ADD_USER_FAIL:
      return {
        ...prevState,
        loading: false,
        errorMessage: payload,
      };

    default:
      return prevState;
  }
};
